/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import pic1 from '../../images/about/1.png';
import pic2 from '../../images/about/pic2.png';
import {Accordion, AccordionItem} from "@nextui-org/react";
import { useEffect, useState } from 'react';

function About()
{
    // SCROLL TO TOP ON PAGE 
    const [isVisible, setIsVisible] = useState(false);
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
  
    useEffect(() => {
      const handleScroll = () => {
        setIsVisible(window.pageYOffset > 300);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      scrollToTop();
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
    return(
        <>
        
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden dark:bg-zinc bg-stone py-6 sm:py-12">
  <div className="w-full items-center mx-auto max-w-screen-lg">
    <div className="group grid w-full grid-cols-1 sm:grid-cols-2 gap-6">
      <div className="relative flex before:block before:absolute before:h-1/6 before:w-2 before:bg-stone-200 before:top-0 before:right-0 before:rounded-lg before:transition-all group-hover:before:bg-orange-300 overflow-hidden">
        <div className="absolute bottom-0 right-0 bg-blue-500 w-full sm:w-4/6 overflow-hidden flex flex-col justify-center rounded-xl group-hover:bg-sky-600 transition-all shadow-2xl">
          <img src={pic1} alt=""/>
        </div>
        
        <div className="h-2/3 rounded-xl overflow-hidden">
          <img src={pic2} className="h-full w-full" alt=""/>
        </div>
        <span className="-rotate-90 font-bold mt-8 mb-8 r gap-2 h-8 text-2xl dark:text-stone text-zinc leading-8" >
          <span>About <br/> Us</span>
        </span>
        {/*  <a className="absolute h-20 bg-blue-500 w-20 flex items-center justify-center rounded-full bottom-10 left-10 text-white before:block before:absolute before:h-20 before:w-20 before:bg-sky-100 before:rounded-full group-hover:before:animate-ping before:-z-10 hover:bg-sky-600" href="">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
        </svg>
        </a> */}
      </div>
      <div>
        <div className="pl-6 sm:pl-12">
          <h2 className="text-3xl sm:text-5xl font-medium mb-6 dark:text-stone text-zinc">Your Idea, Our Software Services</h2>
          <h3 className="text-xl sm:text-2xl font-medium mb-6">Our limitations are defined solely by the boundaries of your imagination.</h3>
          <p className="mb-6 text-gray-400 text-sm sm:text-md dark:text-stone text-zinc">We provide a wide array of Product Development services, including Website, Mobile App, CMS, ERP, and IoT Development. Our capabilities surpass conventional boundaries, addressing your specific requirements with strict adherence to policies and robust security measures. Additionally, our payment system aligns with the standards set by the State Bank of America.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


   <div className="min-h-screen bg-stone bg-gradient-to-r from-white to-stone dark:bg-gradient-to-r dark:from-zinc dark:to-gray-900 py-20">
        <h1 className="text-5xl font-bold text-center pb-10"><span className="dark:text-stone">How We work</span></h1>

        <div className="max-w-6xl mx-auto flex flex-col gap-10 px-5">
            <div className="flex flex-col md:flex-row  rounded-xl md:bg-transparent gap-10">
                <div className="flex justify-center md:justify-end">
                    <div className="w-[120px] h-[120px] dark:bg-gray-900 bg-stone border border-zinc dark:border-stone shadow-lg rounded-xl p-4 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-14 h-14 text-blue-950">
                            <path className="dark:text-stone text-zinc" fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div className="dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc shadow-lg rounded-md p-4">
                    <h1 className="font-bold text-xl pb-4">1. Account Creation </h1>
                    <p >
                        When purchasing a product plan, account creation is necessary for direct communication and access to additional features, including project purchase history retention.
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row  md:bg-transparent  rounded-xl gap-10">
                <div className="w-full md:w-[500px] flex justify-center md:justify-end">
                    <div className="w-[120px] h-[120px]  shadow-lg dark:bg-gray-900 border dark:border-stone border-zinc  md: rounded-xl md:p-4 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-14 h-14 text-blue-950">
                            <path className="dark:text-stone text-zinc" d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                        </svg>
                    </div>
                </div>
                <div className="dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc  shadow-lg  rounded-xl p-4 ">
                    <h1 className="font-bold text-xl">2. Analyze the requirements  </h1>
                    <p >
                        We will thoroughly review the requirements and contact you through meetings or the built-in messaging feature to understand project needs and create a document plan
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row  md:bg-transparent   rounded-xl  gap-10">
                <div className="w-full md:w-[750px] flex justify-center md:justify-end">
                    <div className="w-[120px] dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc  shadow-lg h-[120px] bg-white  md: rounded-xl p-4 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-14 h-14 text-blue-950">
                            <path className="dark:text-stone text-zinc" fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div className=" dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc   shadow-lg rounded-xl p-4">
                    <h1 className="font-bold text-xl">3. Implementation </h1>
                    <p>
                        Our dedicated team will implement the project creation according to the planned timeline and requirement analysis document.
                    </p> 
                </div>
            </div>

            <div className="flex flex-col md:flex-row    md:bg-transparent rounded-xl gap-10">
                <div className="w-full md:w-[500px] flex justify-center md:justify-end">
                    <div className="shadow-lg w-[120px] h-[120px] dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc rounded-xl p-4 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-14 h-14 text-blue-950">
                            <path className="dark:text-stone text-zinc" fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div className="dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc  shadow-lg  rounded-xl p-4 ">
                    <h1 className="font-bold text-xl">4. Testing </h1>
                    <p>We will facilitate an extensive testing phase, providing you with access to the product to conduct thorough evaluation and address any identified issues promptly.</p>                        
                </div>
            </div>

            <div className="flex flex-col md:flex-row  md:bg-transparent rounded-xl gap-10">
                <div className="flex justify-center md:justify-end">
                    <div className="w-[120px]  h-[120px] dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc  shadow-lg  rounded-xl p-4 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-14 h-14 text-blue-950">
                            <path className="dark:text-stone text-zinc" d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                            <path className="dark:text-stone text-zinc" d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                        </svg>
                    </div>
                </div>
                <div className="  shadow-lg  rounded-xl p-4 dark:bg-gray-900 bg-stone text-zinc dark:text-stone border dark:border-stone border-zinc">
                    <h1 className="font-bold text-xl">5. Deployment </h1>
                    <p>fter the successful completion of rigorous testing procedures and ensuring all requirements are met, we will meticulously proceed with the deployment phase, orchestrating the seamless integration of the product into your operational environment.</p>                        
                </div>
            </div>
        </div>
    </div>     
        
        

        <section className="relative dark:bg-zinc bg-stone h-screen">
<div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
        <div className="absolute top-0 w-full h-full bg-center bg-cover">
          <span id="blackOverlay" className="w-full h-full absolute opacity-75"></span>
        </div>
        <div className="container relative mx-auto">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div className="pr-12">
                <h1 className="text-zinc dark:text-stone font-bold text-5xl">
                  Our Values
                </h1>
                <p className="mt-4 text-lg text-gray-500 ">
                We adhere to fundamental principles fostering mutual trust between our clients and our team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" >
          <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </div>
      <section className="dark:bg-zinc bg-stone">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words dark:bg-zinc bg-stone border border-stone w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-stone border border-zinc">
                    {/* <i className="fas fa-award"></i> */}
                    {/* <img src=''/> */}
                    <img width="50" height="50" src="https://img.icons8.com/ios/50/000000/security-shield-green.png" alt="security-shield-green"/>
                    </div>
                  <h6 className="text-xl font-bold dark:text-stone dark:text-stone text-zinc">Security</h6>
                  <p className="mt-2 mb-4 dark:text-stone dark:text-stone text-zinc">
                  We prioritize security to enhance user experience and foster trust within our organization.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words dark:bg-zinc bg-stone border border-stone w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-stone border border-zinc">
                  <img width="50" height="50" src="https://img.icons8.com/ios/50/000000/online-support--v1.png" alt="online-support--v1"/>                    
                  </div>
                  <h6 className="text-xl font-bold dark:text-stone dark:text-stone text-zinc">Customer Services</h6>
                  <p className="mt-2 mb-4 dark:text-stone dark:text-stone text-zinc">
                  Our commitment to exceptional customer service ensures satisfaction at every step of your journey with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words dark:bg-zinc bg-stone border border-stone w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-stone border border-zinc">
                  <img width="50" height="50" src="https://img.icons8.com/ios/50/000000/card-security.png" alt="card-security"/>                  </div>
                  <h6 className="text-xl font-bold dark:text-stone dark:text-stone text-zinc">Payment Protection</h6>
                  <p className="mt-2 mb-4 dark:text-stone dark:text-stone text-zinc">
                  Payment protection is paramount, ensuring secure transactions and peace of mind for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
</div>
      </section>
      </section>
        <div className="bg-stone dark:bg-zinc">
            <div className="container mx-auto">
                <div role="article" className="bg-stone dark:bg-zinc py-12 md:px-8">
                    <div className="px-4 xl:px-0 py-10">
                        <div className="flex flex-col lg:flex-row flex-wrap">
                            <div className="mt-4 lg:mt-0 lg:w-3/5">
                                <div>
                                    <h1 className="text-3xl ml-2 lg:ml-0 lg:text-4xl font-bold dark:text-stone text-zinc tracking-normal lg:w-11/12">Frequently asked questions</h1>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="px-6 xl:px-0 ">
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pb-6 gap-8">
                            
                            {/* ACCOUNT OVERVIEW */}
                            <div role="cell" className="bg-stone dark:bg-zinc">
                                <div className="bg-stone dark:bg-zinc border border-zinc dark:border-stone p-5 rounded-lg relative h-full w-full">
                                    <span><img className="bg-gray-200 text-stone p-2 mb-5 rounded-full" src="https://i.ibb.co/27R6nk5/home-1.png" alt="home-1" /></span>
                                    <h1 className="pb-4 text-2xl font-semibold">Account Overview</h1>
                                    <div className="my-5">
                                        <Accordion variant="splitted">
                                            <AccordionItem key="1" aria-label="Accordion 1" title={<p className='text-sm font-bold'>Why do i need to create an account?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>Creating an account facilitates interaction with our team and grants access to additional features within your account dashboard.</p>
                                            </AccordionItem>
                                            <AccordionItem key="2" aria-label="Accordion 2" title={<p className='text-sm font-bold'>What do i do if i am unable to create an account?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>Give us a message from <Link to='/contact' className='hover:font-bold duration-400 transition-color transform hover:text-aqua text-blue-200'>Contact Us </Link> page and we will handle it for you</p>
                                            </AccordionItem>
                                            <AccordionItem key="3" aria-label="Accordion 3" title={<p className='text-sm font-bold'>Can i use google to create an account?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>Yes. We allow creation of account using Google and we plan to make it easier by adding more ways to ease your account creation process.</p>
                                            </AccordionItem>
                                            </Accordion>
                                    </div>
                                </div>
                            </div>

                            {/* PLANS */}
                            <div role="cell" className="bg-stone dark:bg-zinc ">
                                <div className="bg-stone dark:bg-zinc border border-zinc dark:border-stone p-5 rounded-lg relative h-full w-full">
                                    <span><img className="bg-gray-200 p-2 mb-5 rounded-full" src="https://i.ibb.co/bdGyLYk/pricetags-1.png" alt="pricetags-1" /></span>
                                    <h1 className="pb-4 text-2xl font-semibold">Subscription Plans</h1>
                                    <div className="my-5">
                                        <Accordion variant="splitted">
                                            <AccordionItem key="1" aria-label="Accordion 1" title={<p className='text-sm font-bold'>I don't see a plan that suits me</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>We have a Custom Plan option for every service for this specific purpose.</p>
                                            </AccordionItem>
                                            <AccordionItem key="2" aria-label="Accordion 2" title={<p className='text-sm font-bold'>What do i need to do to make my own Plan?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>Select Custom Plan from your desired service and fill the form. We will get in contact with you by giving you a quote.</p>
                                            </AccordionItem>
                                            <AccordionItem key="3" aria-label="Accordion 3" title={<p className='text-sm font-bold'>Do i need an account just to get a quote?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>No. You can request a qoute by clicking on Get A Qoute button from anywhere in the website.</p>
                                            </AccordionItem>
                                            </Accordion>
                                    </div>
                                </div>
                            </div>

                            {/* PAYMENTS */}
                            <div role="cell" className="bg-stone dark:bg-zinc">
                                <div className="bg-stone dark:bg-zinc border border-zinc dark:border-stone p-5 rounded-lg relative h-full w-full">
                                    <span><img className="bg-gray-200 p-2 mb-5 rounded-full" src="https://i.ibb.co/GT4KHvJ/card-1.png" alt="home-1" /></span>
                                    <h1 className="pb-4 text-2xl font-semibold">Payment Options</h1>
                                    <div className="my-5">
                                        <Accordion variant="splitted">
                                            <AccordionItem key="1" aria-label="Accordion 1" title={<p className='text-sm font-bold'>Is the Payment Safe?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>Yes, we utilize the safest payment integration provided by the State Bank of America. Our payment system employs the same technology trusted by major global corporations.</p>
                                            </AccordionItem>
                                            <AccordionItem key="2" aria-label="Accordion 2" title={<p className='text-sm font-bold'>If I prefer not to pay the full amount in one payment, what are my options?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>We offer milestone-based contracts, where payment is made upon completion of each project milestone.</p>
                                            </AccordionItem>
                                            <AccordionItem key="3" aria-label="Accordion 3" title={<p className='text-sm font-bold'>What are the available payment options for completing my purchase?</p>}>
                                            <p className='text-sm dark:text-gray-400 text-gray-400'>We accept payment options from around the world, including debit cards, PayPal, and more.</p>
                                            </AccordionItem>
                                            </Accordion>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    <hr className="bg-stone mt-28"/>
        </div>
    


        </>
    )
}

export default About;