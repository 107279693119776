/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import {  useDisclosure } from "@nextui-org/react";
import Spline from "@splinetool/react-spline";
import Get_A_Quote from "../../../modals/getaquote";
import logo_banner from '../../../../images/logos/banner.png';
import logo_banner_black from '../../../../images/logos/banner_black.png';
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

function Hero()
{
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const { theme } = useTheme();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Update the state based on screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // 768px is a typical mobile breakpoint
    };

    // Initial call to set initial state
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return(
        <>
        <Get_A_Quote isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange}/>


<div className="lg:min-h-screen xl:min-h-screen mb-28 bg-stone dark:bg-zinc ">
  <div className="flex justify-center items-center">
    <div className="mt-8 m-4">
      {theme === 'dark' ? (
        <div className="black">
          <img src={logo_banner} className="w-full md:w-[740px] mt-20 h-auto"/>
        </div>
      ) : (
        <div className="white">
          <img src={logo_banner_black} className="w-full md:w-[740px] mt-20 h-auto"/>
        </div>
      )}
    </div>
  </div>
      {!isMobile && (
        <div className="flex justify-center items-center">
          <Spline scene="https://prod.spline.design/hFrAmbLshE9z50Bh/scene.splinecode" style={{ width: '90vw', maxWidth: '70vw', maxHeight: '70vh' }} />
        </div>
      )}

  <div className="px-4 md:px-56 xl:px-96 lg:px-full ">
  <div className="mx-auto mt-8 md:mt-12 transform hover:scale-[101%] transition duration-500 relative shadow-xl dark:hover:shadow-indigo-500 hover:shadow-green-500 dark:shadow-indigo-500/60 shadow-green-500/50 rounded-lg">
    <div className="mockup-code  mt-4 md:mt-12 dark:bg-opacity-10 backdrop-blur-md rounded-lg overflow-hidden ring dark:ring-white dark:ring-opacity-50 ring-opacity-50 ring-zinc">
      <pre data-prefix="$" className=" "><code>Turn My <span className="text-purple-500">Idea</span> into <span className="text-green-400">Software</span></code></pre>
      <pre data-prefix=">" className="text-error"><code>processing</code></pre>
      <pre data-prefix=">" className="text-warning my-2"><button onClick={onOpen} className="text-md md:text-md p-0.5 text-orange-500 border border-dotted rounded-lg border-orange-500 hover:bg-orange-500 hover:text-white transition duration-300">Get a quote</button></pre>
    </div>
  </div>
  </div>
</div>



        
        
        </>
    )
}

export default Hero;