/* eslint-disable no-unused-vars */
import {Tabs, Tab} from "@nextui-org/react";
import ERP from "./tabs/erp";
import CMS from "./tabs/cms";
import Website from "./tabs/website";
import Mobile from "./tabs/mobile";
import IOT from "./tabs/iot";
import { useEffect, useState } from "react";

const Prices = () => {
  


  // SCROLL TO TOP ON PAGE 
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.pageYOffset > 300);
    };

    window.addEventListener("scroll", handleScroll);

    scrollToTop();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div>

   <div className="bg-stone dark:bg-zinc py-24 sm:py-32">

    <div className="mx-auto max-w-4xl text-center">
      <h2 className="text-base font-semibold leading-7 text-aqua">Pricing</h2>
      <p className="mt-2 text-4xl font-bold tracking-tight text-zinc dark:text-stone sm:text-5xl">Pricing plans for our Services</p>
    </div>
    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-zinc dark:text-gray-400">Please choose the plans that align most closely with your requirements across all of our services.</p>
        <div className="flex w-full mt-16 flex-col">
      <Tabs aria-label="Options" className='justify-center' color='secondary'>

              {/* CRM/CMS/ERP */}
      <Tab key="ERP" title="ERP System Development">
          <ERP/>
        </Tab>
      <Tab key="CMS" title="CMS Development">
          <CMS/>
        </Tab>

              {/* WEBSITE */}
      <Tab key="WEBSITE" title="Website Development">
          <Website/>
            </Tab>


              {/* MOBILE */}
      <Tab key="MOBILE" title="Mobile App Development">
            <Mobile/>
          </Tab>

              {/* IOT */}
              <Tab key="IOT" title="IOT Development">
                <IOT/>
            </Tab>


        </Tabs>
      </div>
    </div>
    <hr className="bg-white"/>
  </div>

  );
}

export default Prices;
