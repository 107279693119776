/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import ai from '../../../../images/ai/ai.jpg';
import ai2 from '../../../../images/ai/ai2.jpg';
import ai3 from '../../../../images/ai/ai3.jpg';
function Ai()
{
    return(
        <>
        
        <div className="relative flex w-full dark:bg-zinc bg-stone flex-col items-center sm:mt-0 p-4 -mt-8  h-screen">
  <h1
    className="mt-24 dark:text-stone text-zinc max-w-sm text-center text-2xl font-extrabold sm:max-w-4xl sm:text-5xl">
    Empower Your Business with AI 
  </h1>
  <span className="mt-8 dark:text-stone text-zinc  max-w-4xl sm:max-w-4xl text-center text-sm leading-relaxed">
  Integrate cutting-edge Artificial Intelligence (AI) solutions into your business strategy to gain a competitive edge in the market.
  </span>

<section className="max-w-7xl  mx-auto px-4 sm:px-6 mt-28">
    <article>
        <section className="mt-6 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-8">
            <article className="shadow-xl dark:hover:shadow-indigo-500 hover:shadow-green-500 dark:shadow-indigo-500/60 shadow-green-500/50 rounded-lg relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
                        style={{
                          backgroundImage: `url(${ai2})`
                        }}    
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-75 transition duration-300 ease-in-out"></div>
                <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-center">
                    <h3 className="text-center">
                        <a className="text-white text-2xl font-bold text-center" >
                            <span className="absolute inset-0"></span>
                            Harnessing Artificial Intelligence for Streamlined Enterprise Resource Planning
                        </a>
                    </h3>
                </div>
            </article>
            <article className="shadow-xl dark:hover:shadow-indigo-500 hover:shadow-green-500 dark:shadow-indigo-500/60 shadow-green-500/50 rounded-lg relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
            style={{
              backgroundImage: `url(${ai})`
            }}                
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-75 transition duration-300 ease-in-out"></div>
                <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-center">
                    <h3 className="text-center">
                        <a className="text-white text-2xl font-bold text-center" >
                            <span className="absolute inset-0"></span>
                            Unleashing the Potential of Intelligent Technologies
                        </a>
                    </h3>
                </div>
            </article>
            <article className="shadow-xl dark:hover:shadow-indigo-500 hover:shadow-green-500 dark:shadow-indigo-500/60 shadow-green-500/50 rounded-lg relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
            style={{
              backgroundImage: `url(${ai3})`
            }}                       
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-75 transition duration-300 ease-in-out"></div>
                <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-center">
                    <h3 className="text-center">
                        <a className="text-white text-2xl font-bold text-center">
                            <span className="absolute inset-0"></span>
                            Chatbots Reshaping Application Experiences
                        </a>
                    </h3>
                </div>
            </article>
        </section>
    </article>
</section>
</div> 

        </>
    )
}

export default Ai;