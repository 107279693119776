import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Textarea, Input, Select, SelectItem } from "@nextui-org/react";
import {database} from '../../firebase/firebase';
import { push, ref } from 'firebase/database';
import { faPaperPlane, } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function Get_A_Quote({ isOpen, onOpen, onOpenChange }) {

    // For Toast
    
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastIcon, setToastIcon] = useState(null);
    const [toastColor, setToastColor] = useState('');

    // For Form data
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");

    const form = useRef();


    // FUNCTION FOR SENDING A MESSAGE 
    const sendMessageAndCreateUser = (userId, userName, userMessage,onClose) => {
        if(userId==='' || userName==='' || userMessage==='' || form.current.service.value==='')
        {
            setToastMessage("Please Enter All Details");
            setToastColor('#ff2e2e');
            setToastIcon(faTriangleExclamation);
            setToast(true);
            
        }
        else{

        const userRef = ref(database, `quote`);
        const now = new Date();
      
            // Format time
            const formattedTime = now.toLocaleTimeString([], { 
              hour: 'numeric', 
              minute: '2-digit', 
              second: '2-digit', 
              hour12: true 
            });
            
            // Format date
            const formattedDate = now.toLocaleDateString([], {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            });
            push(userRef, {
                email:userId,
                name: userName,
                message: userMessage,
                service:form.current.service.value,
                read:false,
                date:formattedDate,
                time:formattedTime,
                type:'inbox',
                group:'quote'
            })
        .then(() => {
            setToastMessage("Message sent successfully");
            setToastIcon(faPaperPlane);
            setToastColor('#ffffff')
            setToast(true);
            setEmail('');
            setMessage('');
            setName('');
            onClose();
        })
        .catch((error) => {
            setToastMessage("Error sending message. Please try another means of communicating");
        });
    }
    };
      
    //   CODE FOR CLOSING THE TOAST MESSAGE
    useEffect(() => {
        let timeout;
            timeout = setTimeout(() => {
                setToastMessage('');
                setToast(false);
                setToastColor('')
            }, 5000);
    
        return () => clearTimeout(timeout);
    }, [toastMessage]);
    

    const services = [
        {
            value: 'CRM/ERP Systems',
            name: 'CRM/ERP System Development'
        },
        {
            value: 'Website Development',
            name: 'Website Development'
        },
        {
            value: 'IOT Development',
            name: 'IOT Development'
        },
        {
            value: 'Mobile Application Development',
            name: 'Mobile Application Development'
        },
    ];

    

    return (
        <>
        {toast && (
        <div className="fixed z-50 toast toast-bottom toast-start ease-in">
            <div className="alert border dark:bg-zinc dark:border-stone dark:text-white bg-stone border-zinc text-zinc ">
            <FontAwesomeIcon icon={toastIcon} style={{color: toastColor,}}/>
            <div class="border-r border-gray-500 h-4"></div>
            <span>{toastMessage}</span>
            </div>
        </div>
        )}


        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col text-xl font-bold dark:text-stone text-zinc gap-1">Get A Quote</ModalHeader>
                        <ModalBody>
                            {/* <form ref={form} onSubmit={sendEmail}> */}
                            <form ref={form}>
                                <div>
                                    <div className="w-full mt-4 flex flex-col gap-2 max-w-full">
                                        <Input
                                            labelPlacement="outside"
                                            isRequired
                                            label="Email"
                                            placeholder="Enter your email"
                                            value={email}
                                            name='email'
                                            onValueChange={setEmail}
                                        />
                                    </div>
                                    <div className="w-full mt-4 flex flex-col gap-2 max-w-full">
                                        <Input
                                            isRequired
                                            label="Name"
                                            placeholder="Enter your name"
                                            value={name}
                                            name='name'
                                            onValueChange={setName}
                                            labelPlacement="outside"
                                        />
                                    </div>
                                    <div className="w-full mt-4 flex flex-col gap-2 max-w-full">
                                        <Input
                                            label="Phone (Optional)"
                                            placeholder="Enter your Phone"
                                            value={phone}
                                            name='phone'
                                            onValueChange={setPhone}
                                            labelPlacement="outside"
                                        />
                                    </div>
                                    <div className="w-full mt-4 flex flex-col gap-2 max-w-full">
                                        <Select
                                            isRequired
                                            labelPlacement="outside"
                                            label="Service"
                                            placeholder="Select a Service"
                                            className="max-w-full"
                                            name='service'
                                        >
                                            {services.map((service) => (
                                                <SelectItem key={service.value} value={service.value}>
                                                    {service.name}
                                                </SelectItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="w-full mt-4 flex flex-col gap-2 max-w-full">
                                        <Textarea
                                            isRequired
                                            label="Message"
                                            labelPlacement="outside"
                                            placeholder="Enter your description"
                                            value={message}
                                            name='message'
                                            onValueChange={setMessage}
                                        />
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Close
                            </Button>
                            <Button onClick={()=>{sendMessageAndCreateUser(email,name, message,onClose);}} className="dark:bg-zinc border dark:border-stone border-zinc bg-stone dark:text-stone text-zinc hover:dark:text-zinc hover:text-stone hover:dark:bg-stone hover:bg-zinc ">
                                Submit
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
        </>
    );
}

export default Get_A_Quote;
