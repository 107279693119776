import {Routes,Route} from 'react-router-dom';
import Home from '../pages/home/home';
import Public_Navbar from '../pages/navbar/navbar';
import Footer from '../pages/footer/footer';
import Prices from '../pages/plans/plans';
import Login from '../pages/registration/login';
import Register from '../pages/registration/register';
import Contact from '../pages/contact/contact';
import PageNotFound from '../pages/404page/404page';
import About from '../pages/about/about';
import WebDev from '../pages/services/webdevelopment';
import ERP from '../pages/services/erp';
import CMS from '../pages/services/cms';
import Mobile from '../pages/services/mobile';
import IOT from '../pages/plans/tabs/iot';
import IOT_Dev from '../pages/services/iot';

function RoutingPublic()
{
    return(
    <>
    <Public_Navbar/>
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/plans" element={<Prices/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/website_development" element={<WebDev/>} />
        <Route path="/erp" element={<ERP/>} />
        <Route path="/cms" element={<CMS/>} />
        <Route path="/mobile" element={<Mobile/>} />
        <Route path="/iot" element={<IOT_Dev/>} />
        <Route path="/*" element={<PageNotFound/>} />
    </Routes>
    <Footer/>
    </>
    )
}

export default RoutingPublic;