/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link } from "react-router-dom";
import {
    Input,
    Ripple,
    initTWE,
  } from "tw-elements";
import {Input as NextInput} from "@nextui-org/react";
import {EyeFilledIcon} from "./EyeFilledIcon";
import {EyeSlashFilledIcon} from "./EyeSlashFilledIcon";
// import GoogleIcon from '../../images/icons/google.png';
initTWE({ Input, Ripple })

function Login()
{
    // for Passowrd visibility
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);

    // Variables for Inputs
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');



    // Functions for Inputs
    const handleEmail=(e)=>
    {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handlePassword=(e)=>
    {
        e.preventDefault();
        setPassword(e.target.value);
    }


    // On Submit
    const handleButtonPress=(e)=>
    {
        
        e.preventDefault();
        setPassword(e.target.value);
    }

    return(
    <>
    
    <div className="bg-stone dark:bg-zinc">
        <div className="flex justify-center h-screen">
            <div className="hidden bg-cover lg:block lg:w-2/3 rounded-xl" style={{backgroundImage: "url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)"}}>
                <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                    <div>
                        <h2 className="text-4xl font-bold text-white">Sign In</h2>
                        
                        <p className="max-w-xl mt-3 text-gray-300">Sign in to Codminal for complete access to all features and capabilities. Unlock a world of possibilities tailored to enhance your experience on our platform.</p>
                    </div>
                </div>
            </div>
            
            <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                <div className="flex-1">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">CODMINAL</h2>
                        
                        <p className="mt-3 text-gray-500 dark:text-gray-300">Sign in to access your account</p>
                    </div>

                    <div className="mt-8">
                        <form>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-md text-gray-600 dark:text-gray-200">Email Address</label>
                            </div>
                            <NextInput
                                onChange={handleEmail}
                                variant="bordered"
                                placeholder="Enter your Email"
                                size="lg"
                                className="block w-full mt-2 text-gray-900 placeholder-gray-900 bg-white rounded-md dark:placeholder-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:border-gray-900 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                />

                            <div className="mt-6">
                                <div className="flex justify-between mb-2">
                                    <label htmlFor="password" className="text-md text-gray-600 dark:text-gray-200">Password</label>
                                    <a href="#" className="text-sm text-gray-500  hover:text-aqua hover:dark:text-orange-500 duration-400 transition-color dark:text-gray-400 hover:font-bold ">Forgot password?</a>
                                </div>

                                <NextInput
                                    onChange={handlePassword}
                                    variant="bordered"
                                    placeholder="Enter your password"
                                    endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <EyeSlashFilledIcon className="text-xl text-default-400 pointer-events-none" />
                                        ) : (
                                            <EyeFilledIcon className="text-xl text-default-400 pointer-events-none" />
                                        )}
                                        </button>
                                    }
                                    size="lg"
                                    type={isVisible ? "text" : "password"}
                                    className="block w-full mt-2 text-gray-900 placeholder-gray-900 bg-white rounded-md dark:placeholder-gray-800 dark:bg-gray-900 dark:text-gray-400 dark:border-gray-900 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                            </div>

                            <div className="mt-6">
                                <button
                                    onClick={handleButtonPress}
                                    className="w-full px-4 py-2 tracking-wide  border dark:border-stone transition-colors duration-200 transform dark:hover:text-zinc dark:hover:bg-stone text-zinc hover:text-stone hover:bg-zinc border-zinc dark:text-stone rounded-md ">
                                    Sign in
                                </button>
                            </div>

                        </form>

                        <p className="mt-6 text-sm text-center text-gray-400">Don&#x27;t have an account yet? <Link to="/register" className="text-black focus:outline-none hover:text-zinc dark:hover:text-stone dark:text-aqua hover:font-bold duration-500">Sign up</Link>.</p>
                    </div>
                    
                    <div className="m-2 text-center flex justify-between items-center">
                        <hr className="dark:text-white text-zinc w-[40%]"/>
                        <p className="font-bold dark:text-white text-zinc">OR</p>
                        <hr className="dark:text-white text-zinc w-[40%]"/>
                    </div>

                    <div className="mt-6">
                        <button
                            onClick={handleButtonPress}
                            className="w-full px-4 py-2 flex items-center justify-center border dark:border-stone transition-colors duration-200 transform dark:hover:text-zinc dark:hover:bg-stone text-zinc hover:text-stone hover:bg-zinc border-zinc dark:text-stone rounded-md"
                        >
                        {/* <img className="w-10 mr-2" src={GoogleIcon} alt="Google Icon"/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" x="0px" y="0px" width="60" height="36" viewBox="0 0 50 50">
                            <path className="dark:bg-white" d="M 26 2 C 13.308594 2 3 12.308594 3 25 C 3 37.691406 13.308594 48 26 48 C 35.917969 48 41.972656 43.4375 45.125 37.78125 C 48.277344 32.125 48.675781 25.480469 47.71875 20.9375 L 47.53125 20.15625 L 46.75 20.15625 L 26 20.125 L 25 20.125 L 25 30.53125 L 36.4375 30.53125 C 34.710938 34.53125 31.195313 37.28125 26 37.28125 C 19.210938 37.28125 13.71875 31.789063 13.71875 25 C 13.71875 18.210938 19.210938 12.71875 26 12.71875 C 29.050781 12.71875 31.820313 13.847656 33.96875 15.6875 L 34.6875 16.28125 L 41.53125 9.4375 L 42.25 8.6875 L 41.5 8 C 37.414063 4.277344 31.960938 2 26 2 Z M 26 4 C 31.074219 4 35.652344 5.855469 39.28125 8.84375 L 34.46875 13.65625 C 32.089844 11.878906 29.199219 10.71875 26 10.71875 C 18.128906 10.71875 11.71875 17.128906 11.71875 25 C 11.71875 32.871094 18.128906 39.28125 26 39.28125 C 32.550781 39.28125 37.261719 35.265625 38.9375 29.8125 L 39.34375 28.53125 L 27 28.53125 L 27 22.125 L 45.84375 22.15625 C 46.507813 26.191406 46.066406 31.984375 43.375 36.8125 C 40.515625 41.9375 35.320313 46 26 46 C 14.386719 46 5 36.609375 5 25 C 5 13.390625 14.386719 4 26 4 Z"></path>
                        </svg>

                            Sign in with Google
                        </button>
                    </div>

                </div>
                
            </div>
        </div>
    <hr className="dark:text-white mt-10 dark:bg-zinc"/>
    </div>

    </>
    )
}

export default Login;