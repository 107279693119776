
function Timeline()
{
    return(
        <>
        

        <div className='dark:bg-zinc bg-stone lg:mt-48 mt-2'>
  <div>
    <span className='text-2xl lg:text-5xl dark:text-stone text-zinc mx-8 lg:mx-24 font-bold'>Timeline</span>
  </div>
  <div className='max-w-xl mx-8 lg:mx-24 dark:text-stone text-zinc mt-6'>
    <span>The way we work is rooted in a meticulous process. We begin by thoroughly understanding your requirements, then move on to meticulous design, precise development, thorough testing, and finally, seamless deployment.</span>
  </div>
  <div className='mt-12 lg:mt-28 mx-8 lg:mx-48'>
    <ul className="timeline dark:text-stone text-gray-500 timeline-snap-icon max-md:timeline-compact timeline-vertical">
      <li>
        <div className="timeline-middle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
        </div>
        <div className="timeline-end mb-10">
          <div className="text-lg font-black">Requirement Gathering and Analysis</div>
          Requirement gathering and analysis is essential in software development, involving understanding stakeholder needs and defining technical specifications. This process ensures that the resulting software solution meets client expectations and addresses business objectives effectively.
        </div>
        <hr className='dark:bg-white mt-2'/>
      </li>
        <li>
          <div className="timeline-middle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-start md:text-end mb-10">
            <div className="text-lg font-black">Planning</div>
              <span className='text-left'>Define project scope, objectives, and deliverables. Create a detailed project plan with timelines, resources, and dependencies. Allocate resources and define roles to facilitate smooth execution. Following these steps ensures efficient project management and successful delivery.</span>
          </div>
          <hr className='dark:bg-white mt-2'/>
        </li>
        <li>
          <hr />
          <div className="timeline-middle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end mb-10">
            <div className="text-lg font-black">Design</div>
            
            <span className='text-left'>Design in software development involves creating the architecture and structure of the system based on requirements. It encompasses the visualization and planning of how the software will function and interact with users, ensuring a solid foundation for implementation.</span>
          </div>
          <hr className='dark:bg-white mt-2'/>
        </li>
        <li>
          <hr />
          <div className="timeline-middle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-start md:text-end mb-10">
            <div className="text-lg font-black">Implementation (Coding)</div>
                <span className='text-left'>Implementation, also known as coding, is the stage in software development where developers translate design specifications into actual code. It involves writing, testing, and debugging code to create functional software applications according to the defined requirements.</span>
          </div>
          <hr className='dark:bg-white mt-2'/>
        </li>
        <li>
          <hr />
          <div className="timeline-middle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end mb-10">
            <div className="text-lg font-black">Testing</div>
              <span className=''>Testing in software development is the process of evaluating the functionality and performance of a software application. It involves running various test cases to identify and fix bugs, ensuring that the software meets quality standards and functions as intended.</span>
          </div>
          <hr className='dark:bg-white mt-2'/>
        </li>
        <li>
          <hr />
          <div className="timeline-middle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-start md:text-end mb-10">
            <div className="text-lg font-black">Deployment</div>
              <span className=''>Deployment in software development is the stage where the developed software is released and made available for use. It involves configuring servers, databases, and other infrastructure components to ensure the smooth transition of the software to the production environment, enabling users to access and utilize the application.</span>
          </div>
        </li>
      </ul>
      </div>
</div>
        
        
        </>
    )
}

export default Timeline;