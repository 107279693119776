/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import website from '../../images/website_dev/website.jpg';
import websitecircle from '../../images/website_dev/website_1.jpg';
import website_box from '../../images/website_dev/website_box.jpg';
import eye from '../../images/website_dev/eye.png';
import time from '../../images/website_dev/time_1.png';
import market from '../../images/website_dev/market.png';
import globe from '../../images/website_dev/globe.png';
import { useTheme } from 'next-themes';
import Get_A_Quote from '../modals/getaquote';
import { useDisclosure } from '@nextui-org/react';

function WebDev()
{
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const { theme } = useTheme();

    return(
    <>
      <Get_A_Quote isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange} />
    <div className="relative isolate overflow-hidden dark:bg-zinc bg-stone">
    {theme === 'dark' ? (
        <div className="black">
    <svg className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
        <defs>
        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
        </pattern>
        </defs>
        <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0" />
        </svg>
        <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
    </svg>        
    </div>
      ) : (
        <div className="white">
   <svg className="absolute inset-0 -z-10 h-full w-full stroke-black/10 [mask-image:radial-gradient(100%_100%_at_top_right,black,transparent)]" aria-hidden="true">
    <defs>
        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
        </pattern>
    </defs>
    <svg x="50%" y="-1" className="overflow-visible fill-white/20">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0" />
    </svg>
    <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
</svg>

          </div>
      )}


    <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
        <div className="mt-12 sm:mt-24 lg:mt-0">
            <a  className="inline-flex space-x-6">
            <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-cyan-500 ring-1 ring-inset ring-indigo-500/20">Website Development</span>
            <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
            </span>
            </a>
        </div>
        <h1 className="mt-10 text-4xl font-bold tracking-tight dark:text-stone text-zinc sm:text-5xl uppercase">Unlock Your Online Potential with Expert Website Development.</h1>
        <p className="mt-6 text-lg leading-8 dark:text-stone text-zinc">Experience our unparalleled expertise in website development. We craft custom solutions tailored to elevate your online presence and exceed expectations.</p>
        <div className="mt-10 flex items-center gap-x-6">
            <button onClick={onOpen}  className="rounded-md border dark:border-stone dark:hover:bg-green-400 dark:bg-zinc px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transform transition-color duration-500 hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Get A Quote</button>
            <a href="/plans" className="text-sm font-semibold leading-6 dark:text-stone text-zinc">Go to Prices <span aria-hidden="true">→</span></a>
        </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <img src={website} alt="App screenshot" width="2432" height="1442" className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
        </div>
        </div>
    </div>
    </div>

<section className="relative pt-16 dark:bg-zinc bg-stone">
<div className="container mx-auto">
  <div className="flex flex-wrap items-center">
    <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
      <div className="relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-lg rounded-lg dark:bg-zinc border border-gray-500">
        <img alt="..." src={website_box} className="w-full align-middle rounded-t-lg" />
        <blockquote className="relative p-8 mb-4">
          <h4 className="text-xl font-bold dark:text-white text-zinc">
            Why should i have a website?
          </h4>
          <p className="text-md font-light mt-2 dark:text-white text-zinc">
            Unlock your business's full potential with expert website development services. From sleek designs to seamless functionality, we craft tailored solutions to elevate your online presence and drive growth. Let us help you stand out in the digital landscape and reach your target audience with ease.
          </p>
        </blockquote>
      </div>
    </div>

    <div className="w-full md:w-6/12 px-4">
      <div className="flex flex-wrap">
        <div className="w-full md:w-6/12 px-4">
          <div className="relative flex flex-col mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-sitemap"></i> */}
                <img src={eye} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Enhanced Brand Visibility:</h6>
              <p className="mb-4 dark:text-white text-gray-500">
              A well-designed website boosts brand visibility, aiding customer discovery and fostering trust in your brand.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-drafting-compass"></i> */}
                <img src={globe} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">
                Global Reach
              </h6>
              <p className="mb-4 dark:text-white text-gray-500">
              Websites break geographical barriers, enabling businesses to reach a global audience and expand revenue potential.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
              <img src={time} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">24/7 Accessibility</h6>
              <p className="mb-4 text-blueGray-500 dark:text-white text-gray-500">
              Websites offer round-the-clock access, allowing customers to browse and purchase at their convenience, enhancing satisfaction and sales.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-file-alt"></i> */}
                <img src={market} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Marketing</h6>
              <p className="mb-4 dark:text-white text-gray-500">
              Websites are powerful marketing tools, showcasing offerings and enabling customer interaction, fostering loyalty and driving growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

    <div className="bg-white relative pt-56 pb-96 lg:pt-44 bg-stone dark:bg-zinc">
        <div className="relative xl:container m-auto px-6 md:px-12 lg:px-6">
            <h1 className="sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-zinc text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl dark:text-stone">Diverse Technology Integration Crafting Websites  <br className="lg:block hidden" /> <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-indigo-500 dark:from-green-400 dark:to-indigo-500">with Versatility</span>.</h1>
            <div className="lg:flex">
                <div className="relative font-semibold mt-8 md:mt-16 space-y-8 sm:w-10/12 md:w-2/3 lg:ml-0 sm:mx-auto text-center lg:text-left lg:mr-auto lg:w-6/12">
                    <p className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    Discover the unparalleled versatility of our web development services. At <span className='text-indigo-300 font-bold'>CODMINAL</span>, we harness the latest technologies to create cutting-edge websites on various platforms. Our expertise extends to:
                    </p>
                    <div className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    <ul className=""> 
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>ReactJS</li>
                    </div>
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Laravel</li>
                    </div>
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Django</li>
                    </div>
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>WordPress</li>
                    </div>
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Shopify</li>
                      </div>
                    </ul>
                    </div>
                    <p className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    <br/>Whether you seek the robust functionality of ReactJS, the scalability of Laravel, the user-friendly interface of WordPress, or the seamless e-commerce solutions of Shopify, our expert team delivers tailored solutions to elevate your online presence. With a commitment to innovation and excellence, we transform your vision into a dynamic digital reality, ensuring your website stands out in today's competitive online landscape.
                    </p>
                    {/* <span className="block font-semibold text-gray-500 dark:text-gray-400">The best companion bot for your chat app.</span> */}
                    {/* <div className="grid grid-cols-3 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start">
                        <a aria-label="add to slack" href="#" className="p-4 border border-gray-200 dark:bg-gray-800 dark:border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 dark:hover:border-cyan-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/slack.png" alt="slack logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Slack</span>
                            </div>
                        </a>    
                        <a aria-label="add to chat" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20 dark:hover:border-green-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/chat.png" alt="chat logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Google Chat</span>
                            </div>
                        </a>   
                        <a aria-label="add to zoom" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-blue-400 hover:shadow-lg hover:shadow-blue-600/20 dark:hover:border-blue-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/zoom.png" alt="chat logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Zoom</span>
                            </div>
                        </a>    
                    </div> */}

      
                </div>
                <div className="mt-14 md:mt-0 -right-10 lg:w-5/12 flex justify-center items-center">
                <div className="relative w-full">
                    <div aria-hidden="true" className="absolute scale-75 md:scale-60 lg:scale-80 xl:scale-110 inset-0 m-auto w-full h-full md:w-96 md:h-96 rounded-full rotate-45 bg-gradient-to-r from-indigo-500 to-indigo-300 blur-3xl"></div>
                    <img src={websitecircle} className="rounded-lg relative w-full md:w-[60%] lg:w-[90%] xl:w-[100%] mx-auto" alt="wath illustration" loading="lazy" width="200" height="280" />
                </div>
              </div>

            </div>
        </div>
    </div>
    <hr className="dark:bg-stone bg-zinc"/>
    </>
    )
}

export default WebDev;