/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Hero from './components/hero/hero';
import About from './components/about/about';
import Services from './components/services/services';
import Why_Choose_Us from './components/whychooseus/whychooseus';
import Ai from './components/ai/ai';
import HIW from './components/hiw/hiw';
import Timeline from './components/timeline/timeline';
import Globall from './components/global/global';
import Optimization from './components/optimization/optimization';
import Contact from './components/contact/contact';
import { useEffect, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);



function Home()
{
  // SCROLL TO TOP ON PAGE 
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.pageYOffset > 300);
    };

    window.addEventListener("scroll", handleScroll);

    scrollToTop();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

    return(
      
    <div className='bg-stone dark:bg-zinc'>
      <div id="hero">
        <Hero/>
      </div>

      <div id="about">
        <About/>
      </div>
      
      <div id="services">
        <Services/>
      </div>
      
      <div id="whychooseus">
        <Why_Choose_Us/>
      </div>
      
      <div id="ai">
        <Ai/>
      </div>
      
      <div id="hiw">
        <HIW/>
      </div>
      
      <div id="timeline">
        <Timeline/>
      </div>
      
      <div id="global">
        <Globall/>
      </div>
      
      <div id="optimization">
        <Optimization/>
      </div>
      
      <div id="contact">
        <Contact/>
      </div>
      <hr className='dark:bg-stone bg-zinc mt-28'/>
    </div>
    )
}

export default Home;



