/* eslint-disable jsx-a11y/alt-text */
import { useTheme } from 'next-themes';
import logo from '../../../../images/logos/logo_circle.png';
import logo_dark from '../../../../images/logos/logo_dark.png';

function About()
{
  const { theme } = useTheme();

    return(
        <>
        
        <div id="About" className='min-h-screen mb-[500px] lg:mb-20  '>
  <h1 className="lg:mx-16 m-4 text-zinc dark:text-stone font-bold text-5xl">About Us</h1>
<div  className="hiw-area justify-between h-screen bg-stone dark:bg-zinc expertise lg:flex items-center ">
  <div className='lg:w-1/2'>
    {/* <img className=" lg:ml-24 lg:mt-1 mt-28 " src={about} style={{width:'500px'}} alt="theme-pure" /> */}
    <div className='-mt-36 lg:mt-0 xl:mt-0'>
      {theme === 'dark' ? (
        <div className="black">
          <img src={logo} className=" lg:ml-24 lg:mt-1 mt-28 " style={{width:'500px'}}/>
        </div>
      ) : (
        <div className="white">
          <img src={logo_dark} className=" lg:ml-24 lg:mt-1 mt-28 " style={{width:'500px'}}/>
        </div>
      )}
    </div>
  </div>
  <div className="lg:w-1/2">

    <div className="m-2 md:m-8 lg:m-10 mockup-browser border border-zinc dark:border-stone">
  <div className="mockup-browser-toolbar">
    {/* URL */}
    <div className="input border border-zinc dark:border-stone dark:bg-zinc bg-stone text-stone">https://codminal.com</div>
  </div>
  {/* ABOUT US SECTION */}
  <div className="flex px-4 py-6 border-t border-zinc dark:border-stone">
        <div className="section-title- section-title-4 mb-2 ">
      <span className='text-zinc dark:text-stone'>About Us</span>
      <h1 className="text-2xl font-semibold md:text-2xl lg:text-4xl text-zinc dark:text-stone  ">Crafting tailored digital platforms for enterprises.</h1>
      <div className="hiw-tex mb-2 mt-6">
      <p className='text-zinc dark:text-stone'>At <span className="text-orange-500 font-semibold">CODMINAL</span>, we specialize in crafting cutting-edge digital platforms tailored to your business's unique needs. Our team comprises top-tier developers equipped with the latest industry-relevant technology expertise.</p>
      <p className='text-zinc dark:text-stone mt-4'><span className="text-orange-500 font-semibold">CODMINAL</span> is your premier destination for <span className="text-purple font-semibold">cutting-edge digital solutions</span>. Specializing in <span className="text-aqua font-semibold">CMS</span>, <span className="text-aqua font-semibold">ERP</span>, <span className="text-aqua font-semibold">Website</span>, <span className="text-aqua font-semibold">Mobile Applicatioin</span>, and <span className="text-aqua font-semibold">IoT development</span>, we're your one-stop shop for top-notch technology. Our expert team is committed to delivering bespoke solutions tailored to meet your every digital need. </p>
      <p className='text-zinc dark:text-stone mt-4'>At <span className="text-orange-500 font-semibold">Codminal</span>, we understand the importance of <span className="text-purple font-semibold">optimization</span> in today's digital landscape. That's why our products are meticulously crafted to ensure they are not only functional but also optimized in every aspect, including SEO. Trust Codminal to revolutionize your digital journey and propel your online presence to new heights.</p>
      <div className="hiw-list-wrapper mt-4">
 

      
      </div>
    </div>
    </div>
  </div>
</div>
    
  </div>
</div>

</div>
        
        
        </>
    )
}

export default About;