/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import eye from '../../images/website_dev/eye.png';
import time from '../../images/website_dev/time_1.png';
import market from '../../images/website_dev/market.png';
import globe from '../../images/website_dev/globe.png';
import mobile from '../../images/mobile/mobile.png';
import mobilecircle from '../../images/mobile/mobiler.jpg';
import whymobile from '../../images/mobile/wemymobile.png';
import { useTheme } from 'next-themes';
import Get_A_Quote from '../modals/getaquote';
import { useDisclosure } from '@nextui-org/react';

function Mobile()
{
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const { theme } = useTheme();

    return(
    <>
      <Get_A_Quote isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange}/>
    <div className="relative isolate overflow-hidden dark:bg-zinc bg-stone">
    {theme === 'dark' ? (
        <div className="black">
    <svg className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
        <defs>
        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
        </pattern>
        </defs>
        <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0" />
        </svg>
        <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
    </svg>        </div>
      ) : (
        <div className="white">
   <svg className="absolute inset-0 -z-10 h-full w-full stroke-black/10 [mask-image:radial-gradient(100%_100%_at_top_right,black,transparent)]" aria-hidden="true">
    <defs>
        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
        </pattern>
    </defs>
    <svg x="50%" y="-1" className="overflow-visible fill-white/20">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0" />
    </svg>
    <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
</svg>

          </div>
      )}


    <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
        <div className="mt-12 sm:mt-24 lg:mt-0">
            <a className="inline-flex space-x-6">
            <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-cyan-500 ring-1 ring-inset ring-indigo-500/20">Mobile Application Development</span>
            <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
            </span>
            </a>
        </div>
        <h1 className="mt-10 text-4xl font-bold tracking-tight dark:text-stone text-zinc sm:text-5xl uppercase">Expanding Business Reach with Custom Mobile Applications</h1>
        <p className="mt-6 text-lg leading-8 dark:text-stone text-zinc">Custom mobile applications drive growth by engaging audiences with unique experiences, leveraging cutting-edge technology. Businesses craft tailored solutions to resonate with their market, fostering lasting relationships and unlocking new opportunities. With these apps, businesses enhance visibility, expand reach, and stay ahead in the competitive market landscape.</p>
        <div className="mt-10 flex items-center gap-x-6">
            <button onClick={onOpen}  className="rounded-md border dark:border-stone dark:hover:bg-green-400 dark:bg-zinc px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transform transition-color duration-500 hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Get A Quote</button>
            <a href="/plans" className="text-sm font-semibold leading-6 dark:text-stone text-zinc">Go to Prices <span aria-hidden="true">→</span></a>
        </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <img src={mobile} alt="App screenshot" width="2032" height="442" className="w-[60rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
        </div>
        </div>
    </div>
    </div>

<section className="relative pt-16 dark:bg-zinc bg-stone">
<div className="container mx-auto">
  <div className="flex flex-wrap items-center">
    <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
      <div className="relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-lg rounded-lg dark:bg-zinc border border-gray-500">
        <img alt="..." src={whymobile} className="w-full align-middle rounded-t-lg" />
        <blockquote className="relative p-8 mb-4">
          <h4 className="text-xl font-bold dark:text-white text-zinc">
            Why should i have a Mobile Application?
          </h4>
          <p className="text-md font-light mt-2 dark:text-white text-zinc">
          Owning a mobile app is crucial in today's digital realm. It allows direct access to customers, enhancing engagement and sales. Providing convenient access to products fosters brand loyalty. In a competitive market, a mobile app ensures relevance and sets your business apart.
          </p>
        </blockquote>
      </div>
    </div>

    <div className="w-full md:w-6/12 px-4">
      <div className="flex flex-wrap">
        <div className="w-full md:w-6/12 px-4">
          <div className="relative flex flex-col mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-sitemap"></i> */}
                <img src={eye} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Enhanced Customer Engagement:</h6>
              <p className="mb-4 dark:text-white text-gray-500">
                Direct access to customers fosters stronger connections and interaction opportunities. Push notifications and personalized content increase engagement and retention rates.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-drafting-compass"></i> */}
                <img src={globe} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">
                Increased Brand Visibility:
              </h6>
              <p className="mb-4 dark:text-white text-gray-500">
                Mobile apps serve as a constant reminder of your brand on users' devices. App store presence and searchability expand your brand's reach to new audiences.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
              <img src={time} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Improved Customer Experience:</h6>
              <p className="mb-4 text-blueGray-500 dark:text-white text-gray-500">
              Mobile apps provide a seamless and convenient way for customers to access products or services. Features like in-app purchases, loyalty programs, and personalized recommendations enhance the user experience.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-file-alt"></i> */}
                <img src={market} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Competitive Advantage:</h6>
              <p className="mb-4 dark:text-white text-gray-500">
              Having a mobile app sets your business apart from competitors. It demonstrates innovation and adaptability, attracting tech-savvy customers and boosting brand credibility.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

    <div className="bg-white relative pt-56 pb-96 lg:pt-44 bg-stone dark:bg-zinc">
        <div className="relative xl:container m-auto px-6 md:px-12 lg:px-6">
            <h1 className="sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-zinc text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl dark:text-stone">Innovative Technologies Fuel Mobile App <br className="lg:block hidden" /> <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-indigo-500 dark:from-green-400 dark:to-indigo-500">Development Solutions</span>.</h1>
            <div className="lg:flex">
                <div className="relative font-semibold mt-8 md:mt-16 space-y-8 sm:w-10/12 md:w-2/3 lg:ml-0 sm:mx-auto text-center lg:text-left lg:mr-auto lg:w-6/12">
                    <p className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                      Innovative Technologies Fuel Mobile App Development Solutions" encapsulates the dynamic process of leveraging cutting-edge tools and frameworks to create compelling mobile applications. By harnessing a diverse array of technologies, developers craft solutions that enhance user experiences and drive business growth.
                    </p>
                    <p className='text-gray-700 dark:text-gray-300'>Key technologies utilized in ERP development include:</p>
                    <div className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    <ul className="">
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Cross-Platform Frameworks: Frameworks like React Native and Flutter enable developers to build apps for multiple platforms using a single codebase.</li>
                        </div>
                        <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Cloud Computing: Services like AWS, Google Cloud Platform, and Microsoft Azure provide scalable infrastructure for hosting mobile apps and managing backend operations.</li>
                        </div>
                        <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                          <li className='dark:bg-black bg-stone m-3 '>Artificial Intelligence and Machine Learning: Integrating AI and ML algorithms enhances app functionalities such as personalized recommendations, image recognition, and natural language processing.</li>
                      </div>
                      <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Internet of Things (IoT) Integration: Mobile apps can communicate with IoT devices, enabling remote control and data monitoring for connected devices.</li>
                      </div>
                    </ul>
                    </div>
                    <p className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    <br/>By embracing these innovative technologies, mobile app developers create solutions that address diverse business needs, from e-commerce and social networking to healthcare and finance. These apps empower businesses to stay competitive, drive user engagement, and unlock new opportunities in the mobile-first era.
                    </p>
                    {/* <span className="block font-semibold text-gray-500 dark:text-gray-400">The best companion bot for your chat app.</span> */}
                    {/* <div className="grid grid-cols-3 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start">
                        <a aria-label="add to slack" href="#" className="p-4 border border-gray-200 dark:bg-gray-800 dark:border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 dark:hover:border-cyan-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/slack.png" alt="slack logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Slack</span>
                            </div>
                        </a>    
                        <a aria-label="add to chat" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20 dark:hover:border-green-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/chat.png" alt="chat logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Google Chat</span>
                            </div>
                        </a>   
                        <a aria-label="add to zoom" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-blue-400 hover:shadow-lg hover:shadow-blue-600/20 dark:hover:border-blue-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/zoom.png" alt="chat logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Zoom</span>
                            </div>
                        </a>    
                    </div> */}

      
                </div>
                <div className="mt-14 md:mt-28 -right-10 lg:w-5/12 flex justify-center items-center">
                <div className="relative w-full">
                    <div aria-hidden="true" className="absolute scale-75 md:scale-60 lg:scale-80 xl:scale-110 inset-0 m-auto w-full h-full md:w-96 md:h-96 rounded-full rotate-45 bg-gradient-to-r from-indigo-500 to-indigo-300 blur-3xl"></div>
                    <img src={mobilecircle} className="rounded-lg relative w-full md:w-[60%] lg:w-[90%] xl:w-[100%] mx-auto" alt="wath illustration" loading="lazy" width="200" height="280" />
                </div>
              </div>
            </div>
        </div>
    </div>
    <hr className="dark:bg-stone bg-zinc"/>
    </>
    )
}

export default Mobile;