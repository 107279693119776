/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import website from '../../images/erp/erp.png';
import eye from '../../images/website_dev/eye.png';
import time from '../../images/website_dev/time_1.png';
import market from '../../images/website_dev/market.png';
import globe from '../../images/website_dev/globe.png';
import erpcircle from '../../images/erp/erperr.jpeg';
import whyerp from '../../images/erp/whyerp.png';
import { useTheme } from 'next-themes';
import Get_A_Quote from '../modals/getaquote';
import { useDisclosure } from '@nextui-org/react';

function ERP()
{
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const { theme } = useTheme();

    return(
    <>
      <Get_A_Quote isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange}/>
    <div className="relative isolate overflow-hidden dark:bg-zinc bg-stone">
    {theme === 'dark' ? (
        <div className="black">
    <svg className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
        <defs>
        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
        </pattern>
        </defs>
        <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0" />
        </svg>
        <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
    </svg>        </div>
      ) : (
        <div className="white">
   <svg className="absolute inset-0 -z-10 h-full w-full stroke-black/10 [mask-image:radial-gradient(100%_100%_at_top_right,black,transparent)]" aria-hidden="true">
    <defs>
        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
        </pattern>
    </defs>
    <svg x="50%" y="-1" className="overflow-visible fill-white/20">
        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0" />
    </svg>
    <rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
</svg>

          </div>
      )}


    <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
        <div className="mt-12 sm:mt-24 lg:mt-0">
            <a className="inline-flex space-x-6">
            <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-cyan-500 ring-1 ring-inset ring-indigo-500/20">Enterprise resource planning (ERP)</span>
            <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
            </span>
            </a>
        </div>
        <h1 className="mt-10 text-4xl font-bold tracking-tight dark:text-stone text-zinc sm:text-5xl uppercase">Maximizing Efficiency Unleashing the Power of ERP Solutions</h1>
        <p className="mt-6 text-lg leading-8 dark:text-stone text-zinc">ERP (Enterprise Resource Planning) systems streamline operations by integrating key functions into a cohesive platform. From finance to supply chain management, ERP optimizes processes, fosters collaboration, and delivers real-time insights. By centralizing data and automating tasks, businesses enhance efficiency, agility, and competitiveness in today's fast-paced market environment.</p>
        <div className="mt-10 flex items-center gap-x-6">
            <button onClick={onOpen}  className="rounded-md border dark:border-stone dark:hover:bg-green-400 dark:bg-zinc px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transform transition-color duration-500 hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Get A Quote</button>
            <a href="/plans" className="text-sm font-semibold leading-6 dark:text-stone text-zinc">Go to Prices <span aria-hidden="true">→</span></a>
        </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <img src={website} alt="App screenshot" width="2432" height="1442" className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
        </div>
        </div>
    </div>
    </div>

<section className="relative pt-16 dark:bg-zinc bg-stone">
<div className="container mx-auto">
  <div className="flex flex-wrap items-center">
    <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-78">
      <div className="relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-lg rounded-lg dark:bg-zinc border border-gray-500">
        <img alt="..." src={whyerp} className="bg-white dark:bg-zinc w-full align-middle rounded-t-lg" />
        <blockquote className="relative p-8 mb-4">
          <h4 className="text-xl font-bold dark:text-white text-zinc">
            Why should i have an ERP?
          </h4>
          <p className="text-md font-light mt-2 dark:text-white text-zinc">
          Implementing an ERP system enhances operational efficiency, streamlines processes, and provides real-time insights, driving business growth. With seamless integration across departments, ERP fosters collaboration and empowers informed decision-making, ensuring agility in today's dynamic market.
          </p>
        </blockquote>
      </div>
    </div>

    <div className="w-full md:w-6/12 px-4">
      <div className="flex flex-wrap">
        <div className="w-full md:w-6/12 px-4">
          <div className="relative flex flex-col mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-sitemap"></i> */}
                <img src={eye} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Efficiency Enhancement:</h6>
              <p className="mb-4 dark:text-white text-gray-500">
              Maximizes operational efficiency by integrating processes and automating tasks, reducing manual effort and errors.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-drafting-compass"></i> */}
                <img src={globe} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">
              Informed Decision-Making
              </h6>
              <p className="mb-4 dark:text-white text-gray-500">
              Provides real-time insights and accurate data through centralized systems, empowering better strategic planning and forecasting.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 mt-4">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
              <img src={time} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Enhanced Collaboration:</h6>
              <p className="mb-4 text-blueGray-500 dark:text-white text-gray-500">
              Facilitates seamless communication and teamwork across departments and locations, promoting transparency and alignment.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0">
            <div className="px-4 py-5 flex-auto">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                {/* <i className="fas fa-file-alt"></i> */}
                <img src={market} />
              </div>
              <h6 className="text-xl mb-1 font-semibold dark:text-white text-zinc">Scalability and Adaptability</h6>
              <p className="mb-4 dark:text-white text-gray-500">
                Adapts to business growth and changing needs, offering flexibility to customize workflows and configurations to fit unique requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

    <div className="bg-white relative pt-56 pb-96 lg:pt-44 bg-stone dark:bg-zinc">
        <div className="relative xl:container m-auto px-6 md:px-12 lg:px-6">
            <h1 className="sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-zinc text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl dark:text-stone">Diverse Technology Crafting ERP Solutions Innovating for <br className="lg:block hidden" /> <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-indigo-500 dark:from-green-400 dark:to-indigo-500">Operational Excellence</span>.</h1>
            <div className="lg:flex">
                <div className="relative font-semibold mt-8 md:mt-16 space-y-8 sm:w-10/12 md:w-2/3 lg:ml-0 sm:mx-auto text-center lg:text-left lg:mr-auto lg:w-6/12">
                    <p className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    Diverse technologies converge in crafting ERP solutions, ensuring seamless integration and optimal performance. From robust database management systems to sophisticated cloud computing platforms, each component plays a vital role in enhancing operational efficiency and driving business success. Advanced programming languages such as Java, Python, and C# facilitate the development of scalable and customizable ERP applications, while APIs enable seamless connectivity with external systems. Additionally, frameworks like Angular and React provide dynamic user interfaces, enhancing user experience and adoption. With a focus on security, technologies like SSL encryption and multi-factor authentication safeguard sensitive data, ensuring compliance with industry standards and regulations.
                    </p>
                    <p className='text-gray-700 dark:text-gray-300'>Key technologies utilized in ERP development include:</p>
                    <div className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    <ul className="">
                    <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                        <li className='dark:bg-black bg-stone m-3 '>Database Management Systems</li>
                      </div>
                      <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                      <li className='dark:bg-black bg-stone m-3 '>Programming Languages (Java, Python, C#)</li>
                      </div>
                      <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                        <li className='dark:bg-black bg-stone m-3 '>Cloud Computing Platforms</li>
                      </div>
                      <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                        <li className='dark:bg-black bg-stone m-3 '>Programming Languages (Java, Python, C#)</li>
                       </div> 
                       <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                          <li className='dark:bg-black bg-stone m-3 '>APIs for Integration</li>
                        </div>
                        <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                          <li className='dark:bg-black bg-stone m-3 '>Front-end Frameworks (Angular, React)</li>
                        </div>
                        <div className='mt-2 mb-6 dark:bg-black bg-stone border border-zinc transform hover:scale-[101%] transition duration-500 shadow-xl dark:hover:shadow-indigo-500 hover:shadow-indigo-500 dark:shadow-indigo-500/60 shadow-indigo-500/50 rounded-lg'>
                          <li className='dark:bg-black bg-stone m-3 '>Security Protocols (SSL Encryption, Multi-factor Authentication)</li>
                      </div>
                    </ul>
                    </div>
                    <p className="sm:text-lg text-gray-700 dark:text-gray-300 lg:w-">
                    <br/>These technologies work in tandem to create ERP solutions that empower businesses with agility, insight, and innovation, driving sustained growth and competitive advantage.
                    </p>
                    {/* <span className="block font-semibold text-gray-500 dark:text-gray-400">The best companion bot for your chat app.</span> */}
                    {/* <div className="grid grid-cols-3 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start">
                        <a aria-label="add to slack" href="#" className="p-4 border border-gray-200 dark:bg-gray-800 dark:border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 dark:hover:border-cyan-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/slack.png" alt="slack logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Slack</span>
                            </div>
                        </a>    
                        <a aria-label="add to chat" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20 dark:hover:border-green-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/chat.png" alt="chat logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Google Chat</span>
                            </div>
                        </a>   
                        <a aria-label="add to zoom" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-blue-400 hover:shadow-lg hover:shadow-blue-600/20 dark:hover:border-blue-300/30">
                            <div className="flex justify-center space-x-4">
                                <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/zoom.png" alt="chat logo" loading="lazy" width="128" height="128" />
                                <span className="hidden font-medium md:block dark:text-white">Zoom</span>
                            </div>
                        </a>    
                    </div> */}

      
                </div>
                <div className="mt-14 md:-mt-28 -right-10 lg:w-5/12 flex justify-center items-center">
                <div className="relative w-full">
                    <div aria-hidden="true" className="absolute scale-75 md:scale-60 lg:scale-80 xl:scale-110 inset-0 m-auto w-full h-full md:w-96 md:h-96 rounded-full rotate-45 bg-gradient-to-r from-indigo-500 to-indigo-300 blur-3xl"></div>
                    <img src={erpcircle} className="rounded-lg relative w-full md:w-[60%] lg:w-[90%] xl:w-[100%] mx-auto" alt="wath illustration" loading="lazy" width="200" height="280" />
                </div>
              </div>
            </div>
        </div>
    </div>
    <hr className="dark:bg-stone bg-zinc"/>
    </>
    )
}

export default ERP;