/* eslint-disable jsx-a11y/alt-text */
import { Link as Linker } from 'react-router-dom';
import logo from '../../images/logos/logo_circle.png';
import logo_dark from '../../images/logos/logo_dark.png';
import { useTheme } from 'next-themes';
import { HashLink } from 'react-router-hash-link';

function Footer()
{
  const { theme } = useTheme();

    return(
        <>

<footer className="block dark:bg-zinc bg-stone"> 
<div className="py-16 md:py-24 lg:py-12 mx-auto w-full px-5 md:px-10">
<div className="flex-row flex justify-between max-[767px]:flex-col max-[767px]:items-start mt-20">
      <div className="w-full max-w-[560px] max-[991px]:mr-4 max-[991px]:flex-initial max-[767px]:">
        <h2 className="font-bold text-3xl md:text-5xl dark:text-stone text-zinc">Turn Your <span className='text-purple'>Idea</span> into <span className='text-aqua'>Software</span></h2>
      </div>
      <div className="mx-auto xl:mx-1 lg:mx-1 md:mx-1">
  {theme === 'dark' ? (
    <div className="black inline-block">
      <img src={logo} className='w-48 -mt-8'/>
    </div>
  ) : (
    <div className="white inline-block">
      <img src={logo_dark} className='w-48 -mt-8'/>
    </div>
  )}
</div>

    </div>
    <div className="mb-4 w-full border dark:border-stone border-zinc mt-16">
    </div>
    <div className="flex-row flex justify-between max-[991px]:items-center max-[767px]:flex-col max-[767px]:items-start ">
      <div className="font-semibold max-[991px]: max-[479px]:mb-4 max-[991px]:py-1 text-center sm:text-center">
        <button><HashLink to='/#hiw' smooth="true" duration={1400} className="inline-block font-normal dark:text-stone text-zinc transition hover:text-orange-500 dark:hover:text-orange-500 sm:pr-6 lg:pr-12 py-1.5 sm:py-2 pr-6">Home</HashLink></button>
        <button><Linker to='/about' smooth="true" duration={1400} className="inline-block font-normal dark:text-stone text-zinc transition hover:text-orange-500 dark:hover:text-orange-500 sm:pr-6 lg:pr-12 py-1.5 sm:py-2 pr-6">About Us</Linker></button>
        <button><HashLink to='/#services' smooth="true" duration={1400} className="inline-block font-normal dark:text-stone text-zinc transition hover:text-orange-500 dark:hover:text-orange-500 sm:pr-6 lg:pr-12 py-1.5 sm:py-2 pr-6">Services</HashLink></button>
        <button><HashLink to='/#hiw' smooth="true" duration={1400} className="inline-block font-normal dark:text-stone text-zinc transition hover:text-orange-500 dark:hover:text-orange-500 sm:pr-6 lg:pr-12 py-1.5 sm:py-2 pr-6">How We Work?</HashLink></button>
        <button><Linker to='/plans' smooth="true" duration={1400} className="inline-block font-normal dark:text-stone text-zinc transition hover:text-orange-500 dark:hover:text-orange-500 sm:pr-6 lg:pr-12 py-1.5 sm:py-2 pr-6">Prices</Linker></button>
        
      </div>
      <div className="max-[991px]:flex-none">
        <p className="text-zinc dark:text-stone max-[479px]:text-sm">© Copyright 2024. All rights reserved.</p>
      </div>
    </div>
  </div>
</footer>
        
        
        </>
    )
}

export default Footer;