/* eslint-disable jsx-a11y/img-redundant-alt */

function Globall()
{
    return(
        <>
        
        <div className='lg:m-16 xl:m-16 mt-16 lg:mt-6 xl:mt-6 h-screen'>
    <h1 className="xl:text-5xl md:text-4xl text-2xl font-semibold leading-tight text-center dark:text-stone text-zinc  sm:mb-0 mb-12">
    Global service provision with exceptional standards worldwide. 
    {/* <br className="md:block hidden" /> */}
        </h1>
        <div className="md:mt-14 mt-4 relative sm:flex items-center justify-center">
            <img src="https://i.ibb.co/KjrPCyW/map.png" alt="world map image" className="w-full xl:h-full h-96 object-cover object-fill sm:block hidden" />
            <img src="https://i.ibb.co/SXKj9Mf/map-bg.png" alt="mobile-image" className="sm:hidden -mt-10 block w-full h-96 object-cover object-fill absolute z-0" />

            <div className="shadow-lg xl:p-6 p-4 sm:w-auto w-full border dark:border-stone border-zinc dark:bg-zinc bg-stone font-bold sm:absolute relative z-20 sm:mt-0 mt-4 left-0 xl:ml-56 sm:ml-12 xl:-mt-40 sm:-mt-12">
                {/* <p className="text-3xl font-semibold text-gray-800">20K+</p> */}
                <p className="text-base leading-4  text-zinc dark:text-stone">International presence</p>
            </div>
            <div className="shadow-lg xl:p-6 p-4  sm:w-auto w-full border dark:border-stone border-zinc dark:bg-zinc bg-stone font-bold sm:absolute relative z-20 sm:mt-0 mt-4 xl:mt-80 sm:mt-56 xl:-ml-0 sm:-ml-12">
                {/* <p className="text-3xl font-semibold text-gray-800">8K+</p> */}
                <p className="text-base leading-4 text-zinc dark:text-stone">Time zone flexibility</p>
            </div>
            <div className="shadow-lg xl:p-6 p-4 sm:w-auto w-full border dark:border-stone border-zinc dark:bg-zinc bg-stone font-bold sm:absolute relative z-20 md:mt-0 sm:-mt-5 mt-4 right-0 xl:mr-56 sm:mr-24">
                {/* <p className="text-3xl font-semibold text-gray-800">15K+</p> */}
                <p className="text-base leading-4 text-zinc dark:text-stone">Global scalability</p>
            </div>
        </div>
    </div>
        
        
        </>
    )
}

export default Globall;