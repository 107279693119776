import './App.css';
import Chat from './chat/chat';
import RoutingPublic from './routing/publicroutes';

function App() {
  return (
    <>
    <div>
      <RoutingPublic/>
    </div>
    <Chat/>
    </>
  );
}

export default App;
