/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import logo from '../../images/logos/logo_circle.png';
import logo_dark from '../../images/logos/logo_dark.png';
import {Button, ButtonGroup,Dropdown, DropdownTrigger, DropdownMenu, DropdownItem,useDisclosure} from "@nextui-org/react";
// import {UserIcon} from './UserIcon';
import {ChevronDownIcon} from './ChevronDownIcon';
import {Card, CardFooter, Image} from "@nextui-org/react";
import { ThemeSwitcher } from '../../themes/themeswitcher';
import Get_A_Quote from '../modals/getaquote';
import { Link as Linker} from 'react-router-dom';
import { HashLink  } from 'react-router-hash-link';
import { useState } from 'react';
// import avatar from '../../images/avatar/User_Avatar.png';
import cms from '../../images/home_images/1x/Asset 1.png';
import website from '../../images/home_images/1x/website.png';
import iot from '../../images/home_images/1x/iot.png';
import erp from '../../images/home_images/1x/erp.png';
import app from '../../images/home_images/1x/app.png';
import { useTheme } from 'next-themes';
import {Navbar, NavbarBrand, NavbarContent, NavbarMenuToggle, NavbarMenu} from "@nextui-org/react";



function Public_Navbar()
{
  const { theme } = useTheme();

  const [selected,setSelected]=useState('home');


 
  const menuItems = [
    { name: "Home", link: "/" },
    { name: "About Us", link: "/about" },
    { name: "Contact Us", link: "/contact" },
    { name: "Website Development", link: "/website_development" },
    { name: "Mobile App Development", link: "/mobile" },
    { name: "ERP Development", link: "/erp" },
    { name: "CMS Development", link: "/cms" },
    { name: "IOT Development", link: "/iot" },
  ];
  


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();


    return(
        <>
        <Get_A_Quote isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange}/>
      {/* <Navbar> */}
      <Navbar className='fixed' maxWidth='full'>
      <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
      <NavbarBrand>
        <Linker to='/'>
        <div>
      {theme === 'dark' ? (
        <div className="black">
          <img src={logo} className='w-10 my-auto'/>
        </div>
      ) : (
        <div className="white">
          <img src={logo_dark} className='w-10 my-auto'/>
        </div>
      )}
    </div>
          </Linker>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
      <ButtonGroup className=' border border-transparent dark:border-transparent dark:bg-transparent bg-transparent rounded-lg'>
                    <HashLink onClick={()=>setSelected('home')} to='/#hero'smooth="true" duration={1400} >
                    <button
                          className={
                            selected === 'home'
                            ? ' hover:text-green-300 hover:bg-transparent hover:border-transparent dark:text-aqua text-aqua btn border dark:border-transparent border-transparent shadow-transparent dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                            : ' hover:text-green-300 hover:bg-transparent hover:border-transparent btn border border-transparent dark:border-transparent shadow-transparent dark:text-stone text-zinc dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                          }
                        >
                        Home
                        </button>
                      </HashLink>
                    {/* SERVICES */}
                    <Dropdown backdrop="blur"  className=' hover:text-green-300  hover:border-transparent dark:border-gray-700 border m-2 bg-gray-100 dark:bg-zinc '>
                      <DropdownTrigger >
                        <Button 
                        className={
                          selected === 'services'
                            ? 'hover:text-green-300 font-semibold hover:bg-transparent hover:border-transparent dark:text-aqua text-aqua btn border dark:border-transparent border-transparent shadow-transparent dark:bg-transparent bg-transparent  hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                            : 'hover:text-green-300 font-semibold hover:bg-transparent hover:border-transparent btn border border-transparent dark:border-transparent shadow-transparent dark:text-stone text-zinc dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                        }
                          // variant="bordered" 
                        >
                          Services
                          <ChevronDownIcon/>
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="Our Services">
                        <DropdownItem aria-label="Our Services" textValue="Our Services" style={{backgroundColor:'transparent'}} >
                          <div>
                            <p className='dark:text-white text-black font-bold text-2xl mb-1'>Our Services</p>
                            <p className='dark:text-gray-200 text-gray-600 font-leading text-md mb-4'>Choose from our wide range of services</p>
                          </div>
                          <div className='flex'>
                            <div className='start  hover:rounded-lg rounded-lg transition duration-300'>
                            <div className='m-2'>
                              <Linker onClick={()=>setSelected('services')} to='/website_development'>
                            <Card
                              isFooterBlurred
                              radius="lg"
                              className="border-rounded "
                            >
                              <Image
                                alt="Woman listing to music"
                                className="object-cover p-8 -mt-8 hover:scale-105"
                                height={200}
                                src={website}
                                width={200}
                              />
                              <CardFooter className="justify-center dark:before:bg-white/10 dark:border-white/20 before:bg-black/10 border-black/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                                <p className="text-sm font-bold dark:text-white text-black">Website Development.</p>
                              </CardFooter>
                            </Card>
                            </Linker>
                            </div>
                            </div>
                            <div className='start hover:rounded-lg rounded-lg transition duration-300'>
                            <div className='start m-2'>
                            <Linker onClick={()=>setSelected('services')} to='/mobile'>
                            <Card
                              isFooterBlurred
                              radius="lg"
                              className="border-rounded"
                            >
                              <Image
                                alt="Woman listing to music"
                                className="object-cover -mt-10 p-11 hover:scale-105"
                                height={200}
                                src={app}
                                width={200}
                              />
                              <CardFooter className="justify-center dark:before:bg-white/10 dark:border-white/20 before:bg-black/10 border-black/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                                <p className="text-sm font-bold text-black dark:text-white">Mobile App Development.</p>
                              </CardFooter>
                            </Card>
                            </Linker>
                            </div>
                            </div>

                            <div className='start hover:rounded-lg rounded-lg transition duration-300'>
                            <div className='start m-2'>
                            <Linker onClick={()=>setSelected('services')} to='/cms'>
                            <Card
                              isFooterBlurred
                              radius="lg"
                              className="border-rounded"
                            >
                              <Image
                                alt="Woman listing to music"
                                className="object-cover p-9 -mt-9 hover:scale-105"
                                height={200}
                                src={cms}
                                width={200}
                              />
                                <CardFooter className="justify-center dark:before:bg-white/10 dark:border-white/20 before:bg-black/10 border-black/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">                                
                                <p className="text-sm font-bold text-black dark:text-white">CMS Development.</p>
                              </CardFooter>
                            </Card>
                            </Linker>
                            </div>
                          </div>

                            <div className='start hover:rounded-lg rounded-lg transition duration-300'>
                            <div className='start m-2'>
                            <Linker onClick={()=>setSelected('services')} to='/erp'>
                            <Card
                              isFooterBlurred
                              radius="lg"
                              className="border-rounded"
                            >
                              <Image
                                alt="Woman listing to music"
                                className="object-cover p-9 -mt-9 hover:scale-105"
                                height={200}
                                src={erp}
                                width={200}
                              />
                                <CardFooter className="justify-center dark:before:bg-white/10 dark:border-white/20 before:bg-black/10 border-black/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">                                
                                <p className="text-sm font-bold text-black dark:text-white">ERP Development.</p>
                              </CardFooter>
                            </Card>
                            </Linker>
                            </div>
                          </div>

                            <div className='start hover:rounded-lg rounded-lg transition duration-300'>
                            <div className='start m-2'>
                            <Linker onClick={()=>setSelected('services')} to='/iot'>
                            <Card
                              isFooterBlurred
                              radius="lg"
                              className="border-rounded"
                            >
                              <Image
                                alt="Woman listing to music"
                                className="object-cover -mt-10 p-11 hover:scale-105"
                                height={200}
                                src={iot}
                                width={180}
                              />
                                <CardFooter className="justify-center dark:before:bg-white/10 dark:border-white/20 before:bg-black/10 border-black/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">                                <p className="text-sm font-bold dark:text-white text-black">IOT Development.</p>
                              </CardFooter>
                            </Card>
                            </Linker>
                            </div>
                          </div>
                          </div>
                          <hr className='mt-4'/>
                          <p className='dark:text-white text-black mt-2 text-xs'>Please Choose our new Site for Graphical Designs. &nbsp;
                          <a className='text-blue-400 hover:text-purple hover:font-bold transform transition-color duration-500' href="www.codnos.com" >Click Here </a>  
                          to go to our Graphical Designing site. </p>
                          
                        </DropdownItem>
                        
                      </DropdownMenu>
                    </Dropdown>

                    <Linker to='/plans'>
                      <button
                      onClick={()=>setSelected('plans')}
                          className={
                            selected === 'plans'
                            ? ' hover:text-green-300 hover:bg-transparent hover:border-transparent dark:text-aqua text-aqua btn border dark:border-transparent border-transparent shadow-transparent dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                            : ' hover:text-green-300 hover:bg-transparent hover:border-transparent btn border border-transparent dark:border-transparent shadow-transparent dark:text-stone text-zinc dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                          }
                        >
                        Plans
                      </button>
                      </Linker>
                    <Linker to='/about' smooth="true" duration={1400}>
                      <button
                      onClick={()=>setSelected('about')}
                          className={
                            selected === 'about'
                            ? ' hover:text-green-300 hover:bg-transparent hover:border-transparent dark:text-aqua text-aqua btn border dark:border-transparent border-transparent shadow-transparent dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                            : ' hover:text-green-300 hover:bg-transparent hover:border-transparent btn border border-transparent dark:border-transparent shadow-transparent dark:text-stone text-zinc dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                          }
                        >
                        About Us
                      </button>
                      </Linker>
                    <Linker to='/contact' smooth="true" duration={1400}>
                      <button
                          onClick={()=>setSelected('contact')}
                          className={
                            selected === 'contact'
                              ? ' hover:text-green-300 hover:bg-transparent hover:border-transparent dark:text-aqua text-aqua btn border dark:border-transparent border-transparent shadow-transparent dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                              : ' hover:text-green-300 hover:bg-transparent hover:border-transparent btn border border-transparent dark:border-transparent shadow-transparent dark:text-stone text-zinc dark:bg-transparent bg-transparent hover:dark:text-green-300 hover:font-bold tranisition-color duration-400'
                          }
                        >
                        Contact Us
                        </button>
                        </Linker>
                    </ButtonGroup> 
                    {/* <Button onPress={onOpen} className='text-white border dark:text-stone dark:hover:text-zinc dark:hover:bg-stone transition trnasform duration-300 dark:border-stone border-zinc bg-stone dark:bg-zinc hover:bg-zinc hover:text-stone text-zinc font-semibold rounded-md'>Get A Quote</Button> */}

      </NavbarContent>

                <NavbarContent justify="end">
                  <ThemeSwitcher/>
                  <div>
                    <button onClick={onOpen} className='btn bg-stone text-zinc border-zinc hover:bg-zinc hover:text-stone dark:text-stone dark:bg-zinc dark:hover:bg-stone dark:hover:text-zinc border dark:border-stone transform hover:scale-[105%] transition duration-500 rounded-lg'>Get A Quote</button>
                  </div>
                    {/* <div className="flex items-center gap-4 ">
                      <div className="flex items-center gap-4">
                      <Dropdown placement="bottom-end" className='border border-gray-600 text-gray-700 dark:text-stone'>
                        <DropdownTrigger>
                          <Avatar
                            isBordered
                            as="button"
                            className="transition-transform bg-gray-600  dark:bg-gray-900"
                            src={avatar}
                          />
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Profile Actions" variant="flat">
                          <DropdownItem key="login" onClick={() => handleNavigation("/login")}>
                            Sign in
                          </DropdownItem>
                          <DropdownItem key="reigster" onClick={() => handleNavigation("/register")}>
                            Sign Up
                          </DropdownItem>
                          <DropdownItem key="help" onClick={() => handleNavigation("/help")}>
                            Help Center
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
    </div>
     
          
                    </div> */}
                  <NavbarMenu>
                  {menuItems.map((item, index) => (
                    <div key={`${index}`} >
                      <Linker
                        className="w-full btn bg-transparent mt-3 shadow-sm shadow-white border-gray-500 text-zinc dark:text-stone"
                        to={item.link} 
                        size="lg"
                      >
                        {item.name} 
                      </Linker>
                    </div>
                  ))}

                </NavbarMenu>
                  </NavbarContent>
                </Navbar>
        </>
    )
}

export default Public_Navbar;