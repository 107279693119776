/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-pascal-case */
import { useDisclosure } from "@nextui-org/react";
import Get_A_Quote_Plan from "../../modals/getaquote_plan";
import { useState } from "react";



function Website()
{
  const [plan,setPlan]=useState('');
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const handleClick=(open,plan)=>
  {
    setPlan(plan);
    open();
  }
      return(
        <>
        <Get_A_Quote_Plan isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange} service={"Website Development"} plan={plan}/>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                {/* basic plan */}
            <div className="rounded-3xl p-8 ring-1 xl:p-10 bg-stone dark:bg-zinc shadow-xl ring-purple hover:dark:ring-white hover:ring-black hover:scale-[102%] duration-400" >
            <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Shopify</p></div>
                <p className="mt-4 text-sm leading-6 dark:text-gray-300 text-gray-500 font-bold">Empower your business with a Shopify website for seamless online commerce.</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                </p>
                  {/* <div className="flex justify-between"> */}
                    {/* <div className="text-3xl dark:text-stone text-zinc">-</div> */}
                    <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">$300</p></div>
                {/* </div> */}

                <button onClick={()=>{handleClick(onOpen,"Shopify")}} aria-describedby="tier-enterprise" className="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-aqua hover:text-stone hover:dark:bg-purple border dark:text-stone dark:border-purple border-aqua text-aqua  hover:dark:text-stone  duration-300 transform transition focus-visible:outline-white">Buy</button>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    User-Friendly Interface
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Mobile Optimization
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Secure Transactions
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" ill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Extensive App Ecosystem
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Built-in Marketing Tools
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Detailed Analytics
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Free Domain
                  </li>
                </ul>
              </div>

              {/* Standard Plan */}
            <div className="rounded-3xl p-8 ring-1 xl:p-10 bg-stone dark:bg-zinc shadow-xl ring-purple hover:dark:ring-white hover:ring-black hover:scale-[102%] duration-400" >
            <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Wordpress</p></div>
                <p className="mt-4 text-sm leading-6 dark:text-gray-300 text-gray-500 font-bold">Flexible, scalable, and SEO-friendly website solution with extensive plugin options.</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                </p>
                  {/* <div className="flex justify-between"> */}
                    {/* <div className="text-3xl dark:text-stone text-zinc">-</div> */}
                    <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">$600</p></div>
                {/* </div> */}

                <button onClick={()=>{handleClick(onOpen,"Wordpress")}} aria-describedby="tier-enterprise" className="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-aqua hover:text-stone hover:dark:bg-purple border dark:text-stone dark:border-purple border-aqua text-aqua  hover:dark:text-stone  duration-300 transform transition focus-visible:outline-white">Buy</button>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Easy Content Management
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Versatile Plugins
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Scalable E-commerce
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" ill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    SEO-Friendly
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Mobile Responsive
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Community Support
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Free Domain
                  </li>
                </ul>
              </div>

              {/* Enterprise Plan */}
            <div className="rounded-xl p-8 ring-1 xl:p-10 bg-stone dark:bg-zinc shadow-xl ring-purple hover:dark:ring-white hover:ring-black hover:scale-[102%] duration-400" >
            <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Code Crafted Web Solutions</p></div>
                <p className="mt-4 text-sm leading-6 dark:text-gray-300 text-gray-500 font-bold">Bespoke website crafted with advanced coding techniques for unique functionalities.</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                </p>
                  {/* <div className="flex justify-between"> */}
                    {/* <div className="text-3xl dark:text-stone text-zinc">-</div> */}
                    <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Custom Quote</p></div>
                {/* </div> */}

                <button onClick={()=>{handleClick(onOpen,"Code Crafted Solutions")}} aria-describedby="tier-enterprise" className="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-aqua hover:text-stone hover:dark:bg-purple border dark:text-stone dark:border-purple border-aqua text-aqua  hover:dark:text-stone  duration-300 transform transition focus-visible:outline-white">Get A Quote</button>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Custom design
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Scalable architecture
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Control & customization
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" ill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Performance & security
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Third-party integration
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Expert coding
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Free Domain
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Ongoing support
                  </li>
                </ul>
              </div>

            </div>
        </div>
        
        
        </>
    )
}

export default Website;