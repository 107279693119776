/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useDisclosure } from "@nextui-org/react";
import Get_A_Quote_Plan from "../../modals/getaquote_plan";
import { useState } from "react";



function CMS()
{
  const [plan,setPlan]=useState('');
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const handleClick=(open,plan)=>
  {
    setPlan(plan);
    open();
  }
      return(
        <>
        <Get_A_Quote_Plan isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange} service={"CMS Development"} plan={plan}/>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                {/* basic plan */}
            <div className="rounded-3xl p-8 ring-1 xl:p-10 bg-stone dark:bg-zinc shadow-xl ring-purple hover:dark:ring-white hover:ring-black hover:scale-[102%] duration-400" >
            <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Starter Plan</p></div>
                <p className="mt-4 text-sm leading-6 dark:text-gray-300 text-gray-500 font-bold">The Starter Plan offers essential CMS features for building a functional website with basic content management tools.</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                </p>
                  {/* <div className="flex justify-between"> */}
                    {/* <div className="text-3xl dark:text-stone text-zinc">-</div> */}
                    <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">$3,000</p></div>
                {/* </div> */}

                <button onClick={()=>{handleClick(onOpen,"Starter Plan")}} aria-describedby="tier-enterprise" className="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-aqua hover:text-stone hover:dark:bg-purple border dark:text-stone dark:border-purple border-aqua text-aqua  hover:dark:text-stone  duration-300 transform transition focus-visible:outline-white">Buy</button>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Basic Content Management
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    User-Friendly Interface
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Email Assistance
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" ill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Easy Maintenance
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Seamless Integration
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Streamlined Setup
                  </li>
                </ul>
              </div>

              {/* Standard Plan */}
            <div className="rounded-3xl p-8 ring-1 xl:p-10 bg-stone dark:bg-zinc shadow-xl ring-purple hover:dark:ring-white hover:ring-black hover:scale-[102%] duration-400" >
            <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Growth Plan</p></div>
                <p className="mt-4 text-sm leading-6 dark:text-gray-300 text-gray-500 font-bold">The Growth Plan provides additional content modules, social media integration, and customizable website themes for businesses.</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                </p>
                  {/* <div className="flex justify-between"> */}
                    {/* <div className="text-3xl dark:text-stone text-zinc">-</div> */}
                    <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">$8,000</p></div>
                {/* </div> */}

                <button onClick={()=>{handleClick(onOpen,"Growth Plan")}} aria-describedby="tier-enterprise" className="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-aqua hover:text-stone hover:dark:bg-purple border dark:text-stone dark:border-purple border-aqua text-aqua  hover:dark:text-stone  duration-300 transform transition focus-visible:outline-white">Buy</button>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Professional Website
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Social Media Integration
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Advanced Content Management
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" ill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Customizable Themes
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Enhanced Security
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Reliable Support
                  </li>
                </ul>
              </div>

              {/* Enterprise Plan */}
            <div className="rounded-xl p-8 ring-1 xl:p-10 bg-stone dark:bg-zinc shadow-xl ring-purple hover:dark:ring-white hover:ring-black hover:scale-[102%] duration-400" >
            <div className="text-3xl dark:text-stone text-zinc"><p className="font-bold">Enterprise Plan</p></div>
                <p className="mt-4 text-sm leading-6 dark:text-gray-300 text-gray-500 font-bold">The Enterprise Plan provides advanced CMS solutions with dedicated support, ideal for large organizations needing scalability and security.</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                </p>
                  {/* <div className="flex justify-between"> */}
                    {/* <div className="text-3xl dark:text-stone text-zinc">-</div> */}
                    <div className="text-3xl dark:text-stone text-zinc -mt-4"><p className="font-bold">Custom Quote</p></div>
                {/* </div> */}

                <button onClick={()=>{handleClick(onOpen,"Enterprise Plan")}} aria-describedby="tier-enterprise" className="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-aqua hover:text-stone hover:dark:bg-purple border dark:text-stone dark:border-purple border-aqua text-aqua  hover:dark:text-stone  duration-300 transform transition focus-visible:outline-white">Get A Quote</button>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Tailored Solution
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Advanced Security Measures
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Priority Support
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" ill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    AI-Powered Analytics
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Dedicated Project Management
                  </li>
                  <li className="flex gap-x-3 text-zinc dark:text-stone">
                    <svg className="h-6 w-5 flex-none text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path className="dark:text-white text-black" fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    Custom Workflows
                  </li>
                </ul>
              </div>

            </div>
        </div>
        
        
        </>
    )
}

export default CMS;