/* eslint-disable react/jsx-pascal-case */
import { useDisclosure } from "@nextui-org/react";
import { Link } from "react-router-dom";
import Get_A_Quote from "../../../modals/getaquote";

function HIW()
{
  const {isOpen, onOpen, onOpenChange} = useDisclosure();


    return(
        <>
            <Get_A_Quote isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange}/>

<section className="mt-28">
  <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
    <p className="text-center dark:text-stone text-zinc text-sm font-bold uppercase">3 easy steps</p>
    <h2 className="text-center text-3xl dark:text-stone text-zinc font-bold md:text-5xl">How it works</h2>
    <p className="mx-auto mb-12 mt-4 dark:text-stone text-zinc max-w-lg text-center text-[#647084]">We employ three straightforward steps to streamline your project initiation and facilitate swift commencement for enhanced efficiency.</p>
     <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3">
      <div className="flex hover:scale-105 transform transition duration-300 dark:text-stone text-zinc flex-col gap-4 rounded-md border border-solid dark:border-stone border-zinc shadow-lg shadow-blue   p-8 md:p-10">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-zinc dark:bg-stone">
          <p className="text-xl font-bold text-stone dark:text-zinc">1</p>
        </div>
        <p className="text-xl font-semibold">Choose a plan</p>
        <p className="text-md ">Choose a Plan and our team will reach out to you for processing your Project. In case the price is not mentioned, <Link onClick={onOpen} className='text-aqua hover:text-orange-500 duration-300 transition transform'>Get a Quote</Link> and we will reach out to you.</p>
      </div>
      <div className="flex hover:scale-105 transform transition duration-300 dark:text-stone text-zinc flex-col gap-4 rounded-md border border-solid dark:border-stone border-zinc shadow-lg shadow-blue   p-8 md:p-10">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-zinc dark:bg-stone">
          <p className="text-xl font-bold text-stone dark:text-zinc">2</p>
        </div>
        <p className="text-xl font-semibold">Requirement Analysis</p>
        <p className="text-md ">Our team will gather analysis from you, determine project details, and send a PDF document containing all project details. </p>
      </div>
      <div className="flex hover:scale-105 transform transition duration-300 dark:text-stone text-zinc flex-col gap-4 rounded-md border border-solid dark:border-stone border-zinc shadow-lg shadow-blue   p-8 md:p-10">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-zinc dark:bg-stone">
          <p className="text-xl font-bold text-stone dark:text-zinc">3</p>
        </div>
        <p className="text-xl font-semibold">Acknowledgement</p>
        <p className="text-md ">Upon agreemant, a document will be sent to ensure the Project's requirement, timeline and requirements, after which our team will start working. </p>
      </div>

    </div>
  </div>
</section>
        
        
        </>
    )
}

export default HIW;