import graphics2 from '../../../../images/home_images/services/graphics2.png';
import gray_laptop from '../../../../images/home_images/services/services_theme.png';
import crm_cms from '../../../../images/home_images/services/crm.png';
import mobileapp from '../../../../images/home_images/services/mobileapp.png';
import iot from '../../../../images/home_images/services/iot.png';
import { motion} from 'framer-motion';

function Services()
{
    return(
        <>
        
        <div className='grid'>
    <span className='text-3xl lg:mx-16 mx-6 mt-16 lg:text-5xl font-bold dark:text-stone text-zinc'>Our Services</span>
    <span className='text-sm  lg:mx-16 mx-6 mt-6 lg:text-2xl  dark:text-stone text-slate-600'>We provide wide range of services ranging from Development to Graphical Designs.</span>
  </div>
  <section className="mx-auto max-w-7xl px-4 py-12 dark:text-stone text-zinc">
      <div className="mb-4 grid grid-cols-12  ">
        <BounceCard className="transform hover:scale-[101%] transition duration-200 shadow-sm hover:shadow-indigo-500  col-span-12 bg-stone dark:bg-zinc md:col-span-4  border-gray-400">
          {/* <CardTitle></CardTitle> */}
          <div className=" bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl border border-purple p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
            <span className="block text-center font-bold text-zinc dark:text-stone">
              Webiste Development
            </span>
          </div>
          <div className="flex justify-center border mt-8 border-purple">
            <img src={gray_laptop} className='w-56 mt-12 mb-4' alt="Gray Laptop" />
          </div>
      </BounceCard>
        <BounceCard className="transform hover:scale-[101%] transition duration-200 shadow-sm hover:shadow-indigo-500 col-span-12 bg-stone md:col-span-4 dark:bg-zinc border-gray-400">
          {/* <CardTitle>Do a thing</CardTitle> */}
          <div className="transform hover:scale-[101%] transition duration-200 shadow-sm hover:shadow-indigo-500 bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl border border-purple p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
            <span className="block text-center font-bold text-zinc dark:text-stone">
              CMS/ERP Develpment
            </span>
          </div>
          <div className="flex justify-center mt-8 border border-purple">
            <img src={crm_cms} className='w-56 mt-12 mb-4' alt="Gray Laptop" />
          </div>
        </BounceCard>
        <BounceCard className="transform hover:scale-[101%] transition duration-200 shadow-sm hover:shadow-indigo-500 col-span-12 bg-stone md:col-span-4 dark:bg-zinc border-gray-400">
          {/* <CardTitle>Do a thing</CardTitle> */}
          <div className="bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl border border-purple p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
            <span className="block text-center font-bold text-zinc dark:text-stone">
              Mobile Application Development
            </span>
          </div>
          <div className="flex justify-center mt-8 border border-purple">
            <img src={mobileapp} className='w-56 mt-12 mb-4' alt="Gray Laptop" />
          </div>
        </BounceCard>
        <BounceCard className="transform hover:scale-[101%] transition duration-200 shadow-sm hover:shadow-indigo-500 col-span-12 bg-stone md:col-span-4 dark:bg-zinc border-gray-400">
          {/* <CardTitle>Do a thing</CardTitle> */}
          <div className=" bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl border border-purple p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
            <span className="block text-center font-semibold text-zinc dark:text-stone">
              IOT Development
            </span>
          </div>
          <div className="flex justify-center mt-8 border border-purple">
            <img src={iot} className='w-56 mt-12 mb-4' alt="Gray Laptop" />
          </div>
        </BounceCard>
        <BounceCard className="transform hover:scale-[101%] transition duration-200 shadow-sm hover:shadow-indigo-500 col-span-12 bg-stone md:col-span-8 dark:bg-zinc border-gray-400">
          {/* <CardTitle>Do a thing</CardTitle> */}
          <div className="bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl border border-purple p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]">
            <span className="block text-center font-semibold text-zinc dark:text-stone">
              Graphical Designs
            </span>
          </div>
          <div className="flex justify-center mt-8 border border-purple">
            <img src={graphics2} className='w-80 mt-10 mb-4' alt="Gray Laptop" />
          </div>
        </BounceCard>
      </div>

    </section>
        
        
        </>
    )
}

export default Services;


const BounceCard = ({ className, children }) => {
    return (
      <motion.div
        whileHover={{ scale: 0.95, rotate: "-1deg" }}
        className={`group relative min-h-[300px] cursor-pointer overflow-hidden rounded-2xl bg-slate-100 p-8 ${className}`}
      >
        {children}
      </motion.div>
    );
  };
  
  const CardTitle = ({ children }) => {
    return (
      <h3 className="mx-auto text-center text-3xl font-semibold">{children}</h3>
    );
  };